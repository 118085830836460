// react/gatsby imports
import React from "react";
// components
import Layout from "components/layout";
import SEO from "components/layout/seo";
import CourseExplanations from "components/pages/course-page/explanations";
import CoursePriceRange from "components/pages/course-page/price-range";
import EducationQuestions from "components/pages/course-page/education-questions";
import Mailing from "components/common/mailing";
import MasterClassPortfolio from "components/pages/course-page/portfolio/MasterClassPortfolio";
import SchoolSystem from "components/pages/course-page/school-system";
import PaymentChoices from "components/pages/payment-choices";
import MasterClassTemplateOffer from "components/pages/course-page/offer/master-class";
// assets
import ogImage from "assets/images/seo/graphic-system-swiss.jpg";
import ogImageVK from "assets/images/seo/vk/graphic-system-swiss.jpg";
// data
import localData from "data/pages/vhs";
// hooks
import useVHSPortfolio from "hooks/portfolio/useVHSPortfolio";
import useVHSQuery from "hooks/graphql/useVHSQuery";
// utilities
import generateCanonicalLink from "utilities/link-utils";
// constants
import PAGE_SLUGS from "constants/page-slugs";

// Мастер-класс так и не запустили
const VHSPage = () => {
  const prismicData = useVHSQuery();
  const portfolio = useVHSPortfolio();
  const { data } = prismicData.course;
  const { data: seoData } = prismicData.page || {};
  return (
    <Layout>
      <div className="section-top-block" />
      <SEO
        title="Графсистема VHS"
        description={
          seoData?.seodescription?.text ||
          "Мастер-класс по графической системе VHS. В него входят: PDF-презентация, Закрытый телеграм-чат, Видеолекция, Видеоинструкция, Домашнее задание, индивидуальный разбор ДЗ, Вводный урок по InDesign, Макет в портфолио, Бонусный урок."
        }
        keywords={["vhs", "графсистема", "гранич", "granich"]}
        ogImage={seoData?.seoimage?.url || ogImage}
        ogImageVk={seoData?.seoimage?.thumbnails?.vk?.url || ogImageVK}
        url={generateCanonicalLink(PAGE_SLUGS.graphicSystemVHS)}
        // NOINDEX!!!!!!!!!!!
        noIndex
      />
      <MasterClassTemplateOffer courseName="VHS" data={data} />
      <CourseExplanations twoRows data={localData.VHSExplanations} />
      <SchoolSystem />
      <MasterClassPortfolio
        pinterestText={"VHS в Энциклопедии\nГрафдизайна Школы"}
        // ОБНОВИТЬ ССЫЛКУ НА ПИНТЕРЕСТ !!!!!!!!!
        pinterestLink="https://www.pinterest.ru/vadim_granich/airy-layout/"
        portfolio={portfolio}
        graphicSystemName="VHS"
      />
      <div id="participation-section" />
      {data.coursestatus ? (
        <CoursePriceRange
          formId="ltForm3153227"
          valueInputs={[
            {
              id: "1372632",
              price: 900,
            },
            {
              id: "1372669",
              price: 1800,
            },
            {
              id: "1372671",
              price: 2500,
            },
            {
              id: "1372674",
              price: 3500,
            },
          ]}
          courseTitle={data.coursetitle?.text}
          formPostUrl="https://school.granich.design/pl/lite/block-public/process-html?id=863550509"
        />
      ) : (
        <Mailing />
      )}
      <PaymentChoices courseType="Мастер-класс" />
      <EducationQuestions />
    </Layout>
  );
};

export default VHSPage;
