/* eslint-disable */
module.exports = {
  VHSExplanations: [
    {
      id: "ea3c9960-f880-5d8e-af95-d98b1f86359a",
      explanationsImage: {
        src: "/images/graphic-system.jpg",
      },
      explanationsTitle: "Что такое графсистема?",
      explanationsTextTitle:
        "Комбинация элементов и принципов по определённым правилам.",
      explanationsTextDescription:
        "Графсистема строится на правилах дизайна элементов и принципах вёрстки. Усиливает насмотренность дизайнера.",
    },
    {
      id: "c7878326-2114-5e39-9a7a-15be0e3fa7ef",
      explanationsImage: {
        src: "/images/neo-geo/explanations/02.jpg",
      },
      explanationsTitle: "Что такое графсистема UI?",
      explanationsTextTitle: "Воздушные композиции (Airy Layout).",
      explanationsTextDescription:
        "Понимание минимализма. Если вы хотите осознать, почему полупустые макеты работают. Почему это вовсе не пустота, а осмысленное пространство. Чёрный и белый выступают как полноценные цвета. Настраивается тонкая ювелирность пропорций между элементами. Аскетичность и театральность. Всё это в графсистеме Воздуха.",
    },
  ],
};
